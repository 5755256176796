import React, { useState } from 'react';
import axios from 'axios';
import {Button, RadioChangeEvent} from 'antd';
import { Radio } from 'antd';
const BlockAlgorithm = () => {
    const [algorithm, setAlgorithm] = useState<string>('aria');
    const [testType, setTestType] = useState<string>('ae');


    const [key, setKey] = useState<string>('');
    const [iv, setIV] = useState<string>('');
    const [aad, setAad] = useState<string>('');
    const [pl, setPl] = useState<string>('');
    const [cl, setCl] = useState<string>('');
    const [tag, setTag] = useState<string>('');
    const [tagLen, setTagLen] = useState<string>();

    const [result, setResult] = useState<boolean>(false);
    const [response, setResponse] = useState<any>(null);

    const handleSubmit = async () => {
        try {
            const adData = {
                key : key,
                iv : iv,
                aad : aad,
                cl : cl,
                tag : tag
            }

            const aeData = {
                key : key,
                iv : iv,
                aad : aad,
                pl : pl,
                tagLen : tagLen
            }
            const result = await axios.post(`${window.location.protocol}//${window.location.host}/api/block/${testType}/${algorithm}`, testType === 'ae' ? aeData : adData );
            setResponse(result.data);
        } catch (error) {
            setResult(false);
            console.error('Error during the request:', error);
            setResponse(error);
        }
    };

    const algorithmOptions = [
        { label: 'ARIA', value: 'aria' },
        { label: 'LEA', value: 'lea' },
    ];

    const testTypeOption = [
        { label: 'AE', value: 'ae' },
        { label: 'AD', value: 'ad' },
    ];

    const onChangeAlgorithm = ({ target: { value } }: RadioChangeEvent) => {
        setAlgorithm(value);
        setKey('');
        setIV('');
        setAad('');
        setPl('');
        setCl('');
        setTagLen('');
        setResult(false);
        setResponse(null);
        setTag('');
    };

    const onChangeTestType = ({ target: { value } }: RadioChangeEvent) => {
        setTestType(value);
        setKey('');
        setIV('');
        setAad('');
        setPl('');
        setCl('');
        setTagLen('');
        setResult(false);
        setResponse(null);
        setTag('');
    };

    return (

        <div>
            <div style={{marginBottom:"20px"}}>
                <Radio.Group options={algorithmOptions} onChange={onChangeAlgorithm} value={algorithm} optionType="button"  buttonStyle="solid" />
                <Radio.Group style={{marginLeft:"20px"}} options={testTypeOption} onChange={onChangeTestType} value={testType} optionType="button"  buttonStyle="solid" />
            </div>

            <div className="container">
                <div className="input-group">
                    <label>Key:</label>
                    <input type="text" value={key} onChange={(e) => setKey(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label>IV:</label>
                    <input type="text" value={iv} onChange={(e) => setIV(e.target.value)}/>
                </div>

                {testType === 'ad' ?
                    <>
                        <div className="input-group">
                            <label>AAD:</label>
                            <input type="text" value={aad} onChange={(e) => setAad(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label>CipherText:</label>
                            <input type="text" value={cl} onChange={(e) => setCl(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label>Tag:</label>
                            <input type="text" value={tag} onChange={(e) => setTag(e.target.value)}/>
                        </div>
                    </>
                    :
                    <>
                        <div className="input-group">
                            <label>PlanText:</label>
                            <input type="text" value={pl} onChange={(e) => setPl(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label>AAD:</label>
                            <input type="text" value={aad} onChange={(e) => setAad(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label>TagLength:</label>
                            <input type="number" placeholder={"bit Length"} value={tagLen}
                                   onChange={(e) => setTagLen(e.target.value)}/>
                        </div>
                    </>

                }


            </div>
            <div style={{marginTop: "30px"}}>
                <Button style={{width: "100px"}} type="primary" onClick={handleSubmit}>실행</Button>
            </div>
            {response &&(
                <div style={{minHeight: "500px"}}>
                    <h3>Response:</h3>
                    {result ?
                        <pre>{JSON.stringify(response, null, 2)}</pre>
                        :
                        <div className="container">
                            <div className="input-group">
                                <label>Key:</label>
                                <input type="text" value={response.key} readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label>IV:</label>
                                <input type="text" value={response.iv} readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label>AAD:</label>
                                <input type="text" value={response.aad} readOnly={true}/>
                            </div>
                            {testType === 'ad' ?
                                <>
                                    <div className="input-group">
                                        <label>CipherText:</label>
                                        <input type="text" value={response.cl} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label>Tag:</label>
                                        <input type="text" value={response.tag} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label>PlanText:</label>
                                        <input type="text" style={{ color: 'blue' }} value={response.pl} readOnly={true}/>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="input-group">
                                        <label>PlanText:</label>
                                        <input type="text" value={response.pl} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label>CipherText:</label>
                                        <input type="text" style={{ color: 'blue' }}  value={response.cl} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label>Tag:</label>
                                        <input type="text" style={{ color: 'blue' }}  value={response.tag} readOnly={true}/>
                                    </div>

                                </>
                            }

                        </div>
                    }


                </div>
            )}
        </div>
    );
};

export default BlockAlgorithm;
