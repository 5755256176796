import React, { useState } from 'react';
import BlockAlgorithm from "./BlockAlgorithm";

import {BlockOutlined} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import HashAlgorithm from "./HashAlgorithm";
import MacAlgorithm from "./MacAlgorithm";
import DrbgAlgorithm from "./DrbgAlgorithm";

const { Header, Content, Footer, Sider } = Layout;
const WizCryptoLibDemo = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();


    const [selectedComponent, setSelectedComponent] = useState<string>('BLOCK');

    const renderComponent = () => {
        console.log(selectedComponent);
        switch (selectedComponent) {
            case 'BLOCK':
                return <BlockAlgorithm />;
            case 'HASH':
                return <HashAlgorithm />;
            case 'MAC':
                return <MacAlgorithm />;
            case 'DRBG':
                return <DrbgAlgorithm />;
            default:
                return <BlockAlgorithm />;
        }
    };

    const handleClick = (e: any) => {
        console.log('click ', e);
        setSelectedComponent(e.key);
    };

    const items = [
        {
            key: `BLOCK`,
            icon: <BlockOutlined />,
            label: `블록암호알고리즘`,
        },
        {
            key: `HASH`,
            icon: <BlockOutlined />,
            label: `해시알고리즘`,
        },
        {
            key: `MAC`,
            icon: <BlockOutlined />,
            label: `MAC 알고리즘`,
        },
        {
            key: `DRBG`,
            icon: <BlockOutlined />,
            label: `DRBG 알고리즘`,
        },
    ];

    return (
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className="demo-logo-vertical" />
                <Menu theme="dark" mode="inline" onClick={handleClick} defaultSelectedKeys={['BLOCK']} items={items} />
            </Sider>
            <Layout>
                <Content style={{ margin: '24px 16px 0' }}>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {renderComponent()}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Appdign ©{2024} WizCryptoLib Demo
                </Footer>
            </Layout>
        </Layout>
    );

    /*
    return (
        <div>
            <select value={selectedComponent} onChange={handleChange}>
                <option value="BLOCK" >블록알고리즘</option>
                <option value="HASH">해시알고리즘</option>
                <option value="MAC">MAC알고리즘</option>
                <option value="DRBG">DRBG알고리즘</option>
            </select>
            {renderComponent()}
        </div>
    );

     */
};

export default WizCryptoLibDemo;
