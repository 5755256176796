import React from 'react';
import './App.css';
import WizCryptoLibDemo from "./demo/WizCryptoLibDemo";

function App() {
  return (
      <WizCryptoLibDemo />
  );
}

export default App;
