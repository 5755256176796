import React, { useState } from 'react';
import axios from 'axios';
import {Button, RadioChangeEvent} from 'antd';
import { Radio } from 'antd';
const MacAlgorithm = () => {
    const [algorithm, setAlgorithm] = useState<string>('sha2-256');
    const [testType, setTestType] = useState<string>('hmac');

    const [kLen, setKLen] = useState<string>('');
    const [tLen, setTLen] = useState<string>('');
    const [key, setKey] = useState<string>('');
    const [msg, setMsg] = useState<string>('');

    const [result, setResult] = useState<boolean>(false);
    const [response, setResponse] = useState<any>(null);

    const handleSubmit = async () => {
        try {
            const data = {
                kLen:kLen,
                tLen:tLen,
                key : key,
                msg : msg,
            }
           // axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}`;
            const result = await axios.post(`/api/mac/${testType}/${algorithm}`, data );
            setResponse(result.data);
        } catch (error) {
            setResult(false);
            console.error('Error during the request:', error);
            setResponse(error);
        }
    };

    const algorithmOptions = [
        { label: 'SHA-256', value: 'sha2-256'},
        { label: 'SHA-512', value: 'sha2-512' },
    ];

    const testTypeOption = [
        { label: 'HMAC', value: 'hmac' },
    ];

    const onChangeAlgorithm = ({ target: { value } }: RadioChangeEvent) => {
        setAlgorithm(value);
        setKLen('');
        setTLen('');
        setKey('');
        setMsg('');
        setResult(false);
        setResponse(null);
    };

    const onChangeTestType = ({ target: { value } }: RadioChangeEvent) => {
        setTestType(value);
        setKLen('');
        setTLen('');
        setKey('');
        setMsg('');
        setResult(false);
        setResponse(null);
    };

    return (

        <div>
            <div style={{marginBottom:"20px"}}>
                <Radio.Group options={testTypeOption} onChange={onChangeTestType} value={testType} optionType="button"  buttonStyle="solid" />
                <Radio.Group style={{marginLeft:"20px"}} options={algorithmOptions} onChange={onChangeAlgorithm} value={algorithm} optionType="button"  buttonStyle="solid" />
            </div>

            <div className="container">
                <div className="input-group">
                    <label>Klen:</label>
                    <input type="number" value={kLen} onChange={(e) => setKLen(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label>Tlen:</label>
                    <input type="number" value={tLen} onChange={(e) => setTLen(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label>Key:</label>
                    <input type="text" value={key} onChange={(e) => setKey(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label>MSG:</label>
                    <input type="text" value={msg} onChange={(e) => setMsg(e.target.value)}/>
                </div>
            </div>
            <div style={{marginTop: "30px"}}>
                <Button style={{width: "100px"}} type="primary" onClick={handleSubmit}>실행</Button>
            </div>
            {response && (
                <div style={{minHeight: "500px"}}>
                    <h3>Response:</h3>
                    {result ?
                        <pre>{JSON.stringify(response, null, 2)}</pre>
                        :
                        <div className="container">
                            <div className="input-group">
                                <label>Key:</label>
                                <input type="text" value={response.key} readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label>MSG:</label>
                                <input type="text" value={response.msg} readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label>MAC:</label>
                                <input type="text" style={{color:"blue"}} value={response.mac} readOnly={true}/>
                            </div>
                        </div>
                    }


                </div>
            )}
        </div>
    );
};

export default MacAlgorithm;
