import React, { useState } from 'react';
import axios from 'axios';
import {Button, RadioChangeEvent} from 'antd';
import { Radio } from 'antd';
const DrbgAlgorithm = () => {
    const [algorithm, setAlgorithm] = useState<string>('sha2-256');
    const [testType, setTestType] = useState<string>('hashdrbg');
    const [isUsePR, setIsUsePR] = useState<string>('noPR');

    const [reqBitLength, setReqBitLength] = useState<string>(algorithm === 'sha2-256' ? '1024' : '2048');
    const [entropy, setEntropy] = useState<string>('');
    const [nonce, setNonce] = useState<string>('');
    const [personalization, setPersonalization] = useState<string>('');
    const [entropyReseed, setEntropyReseed] = useState<string>('');
    const [additionalInputReseed, setAdditionalInputReseed] = useState<string>('');
    const [additionalInput1, setAdditionalInput1] = useState<string>('');
    const [additionalInput2, setAdditionalInput2] = useState<string>('');

    const [entropyPR1, setEntropyPR1] = useState<string>('');
    const [entropyPR2, setEntropyPR2] = useState<string>('');


    const [result, setResult] = useState<boolean>(false);
    const [response, setResponse] = useState<any>(null);

    const handleSubmit = async () => {
        try {
            const noPRdata = {
                reqBitLength:reqBitLength,
                entropy:entropy,
                nonce:nonce,
                personalization : personalization,
                entropyReseed : entropyReseed,
                additionalInputReseed : additionalInputReseed,
                additionalInput1 : additionalInput1,
                additionalInput2 : additionalInput2,
            }

            const usePRdata = {
                reqBitLength:reqBitLength,
                entropy:entropy,
                nonce:nonce,
                personalization : personalization,
                entropyPR1 : entropyPR1,
                additionalInput1 : additionalInput1,
                entropyPR2 : entropyPR2,
                additionalInput2 : additionalInput2,
            }

            const result = await axios.post(`${window.location.protocol}//${window.location.host}/api/drbg/${testType}/${isUsePR}/${algorithm}`, isUsePR === "noPR" ? noPRdata : usePRdata );
            setResponse(result.data);
        } catch (error) {
            setResult(false);
            console.error('Error during the request:', error);
            setResponse(error);
        }
    };

    const algorithmOptions = [
        { label: 'SHA-256', value: 'sha2-256'},
        { label: 'SHA-512', value: 'sha2-512' },
    ];

    const testTypeOption = [
        { label: 'HashDRBG', value: 'hashdrbg' },
    ];

    const usePROption = [
        { label: 'NoPR', value: 'noPR' },
        { label: 'UsePR', value: 'usePR' },
    ];

    const onChangeUsePR= ({ target: { value } }: RadioChangeEvent) => {
        setIsUsePR(value);
        setReqBitLength( algorithm === 'sha2-256' ? '1024' : '2048');
        setEntropy('');
        setNonce('');
        setPersonalization('');
        setEntropyReseed('');
        setAdditionalInputReseed('');
        setAdditionalInput1('');
        setAdditionalInput2('');
        setEntropyPR1('');
        setEntropyPR2('');
        setResult(false);
        setResponse(null);
    };

    const onChangeAlgorithm = ({ target: { value } }: RadioChangeEvent) => {
        setAlgorithm(value);
        setReqBitLength( value === 'sha2-256' ? '1024' : '2048');
        setEntropy('');
        setNonce('');
        setPersonalization('');
        setEntropyReseed('');
        setAdditionalInputReseed('');
        setAdditionalInput1('');
        setAdditionalInput2('');
        setEntropyPR1('');
        setEntropyPR2('');
        setResult(false);
        setResponse(null);

    };

    const onChangeTestType = ({ target: { value } }: RadioChangeEvent) => {
        setTestType(value);
        setReqBitLength( algorithm === 'sha2-256' ? '1024' : '2048');
        setEntropy('');
        setNonce('');
        setPersonalization('');
        setEntropyReseed('');
        setAdditionalInputReseed('');
        setAdditionalInput1('');
        setAdditionalInput2('');
        setEntropyPR1('');
        setEntropyPR2('');
        setResult(false);
        setResponse(null);
    };


    return (

        <div>
            <div style={{marginBottom:"20px"}}>
                <Radio.Group options={testTypeOption} onChange={onChangeTestType} value={testType} optionType="button"  buttonStyle="solid" />
                <Radio.Group style={{marginLeft:"20px"}} options={usePROption} onChange={onChangeUsePR} value={isUsePR} optionType="button"  buttonStyle="solid" />
                <Radio.Group style={{marginLeft:"20px"}} options={algorithmOptions} onChange={onChangeAlgorithm} value={algorithm} optionType="button"  buttonStyle="solid" />
            </div>

            <div className="container">
                <div className="input-group">
                    <label style={{width: "100px"}}>Request BitLength:</label>
                    <input type="number" value={reqBitLength} onChange={(e) => setReqBitLength(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label style={{width: "100px"}}>EntropyInput:</label>
                    <input type="text" value={entropy} onChange={(e) => setEntropy(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label style={{width: "100px"}}>Nonce:</label>
                    <input type="text" value={nonce} onChange={(e) => setNonce(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label style={{width: "150px"}}>PersonalizationString:</label>
                    <input type="text" value={personalization} onChange={(e) => setPersonalization(e.target.value)}/>
                </div>
                {isUsePR === "noPR" ?
                    <>

                        <div className="input-group">
                            <label style={{width: "150px"}}>EntropyInputReseed:</label>
                            <input type="text" value={entropyReseed}
                                   onChange={(e) => setEntropyReseed(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label style={{width: "150px"}}>AdditionalInputReseed:</label>
                            <input type="text" value={additionalInputReseed}
                                   onChange={(e) => setAdditionalInputReseed(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label style={{width: "110px"}}>AdditionalInput1:</label>
                            <input type="text" value={additionalInput1}
                                   onChange={(e) => setAdditionalInput1(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label style={{width: "110px"}}>AdditionalInput2:</label>
                            <input type="text" value={additionalInput2}
                                   onChange={(e) => setAdditionalInput2(e.target.value)}/>
                        </div>
                    </>
                    :
                    <>
                        <div className="input-group">
                            <label style={{width: "110px"}}>EntropyInputPR1:</label>
                            <input type="text" value={entropyPR1}
                                   onChange={(e) => setEntropyPR1(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label style={{width: "110px"}}>AdditionalInput1:</label>
                            <input type="text" value={additionalInput1}
                                   onChange={(e) => setAdditionalInput1(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label style={{width: "110px"}}>EntropyInputPR2:</label>
                            <input type="text" value={entropyPR2}
                                   onChange={(e) => setEntropyPR2(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label style={{width: "110px"}}>AdditionalInput2:</label>
                            <input type="text" value={additionalInput2}
                                   onChange={(e) => setAdditionalInput2(e.target.value)}/>
                        </div>
                    </>
                }

            </div>
            <div style={{marginTop: "30px"}}>
                <Button style={{width: "100px"}} type="primary" onClick={handleSubmit}>실행</Button>
            </div>
            {response && (
                <div style={{minHeight: "500px"}}>
                    <h3>Response:</h3>
                    {result ?
                        <pre>{JSON.stringify(response, null, 2)}</pre>
                        :
                        <div className="container">
                            <div className="input-group">
                                <label style={{width: "100px"}}>EntropyInput:</label>
                                <input type="text" value={response.entropy} readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label style={{width: "100px"}}>Nonce:</label>
                                <input type="text" value={response.nonce} readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label style={{width: "150px"}}>PersonalizationString:</label>
                                <input type="text" value={response.personalization} readOnly={true}/>
                            </div>
                            {isUsePR === "noPR" ?
                                <>
                                    <div className="input-group">
                                        <label style={{width: "150px"}}>EntropyInputReseed:</label>
                                        <input type="text" value={response.entropyReseed} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label style={{width: "150px"}}>AdditionalInputReseed:</label>
                                        <input type="text" value={response.additionalInputReseed} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label style={{width: "110px"}}>AdditionalInput1:</label>
                                        <input type="text" value={response.additionalInput1} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label style={{width: "110px"}}>AdditionalInput2:</label>
                                        <input type="text" value={response.additionalInput2} readOnly={true}/>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="input-group">
                                        <label style={{width: "110px"}}>EntropyInputPR1:</label>
                                        <input type="text" value={response.entropyPR1} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label style={{width: "110px"}}>AdditionalInput1:</label>
                                        <input type="text" value={response.additionalInput1} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label style={{width: "110px"}}>EntropyInputPR2:</label>
                                        <input type="text" value={response.entropyPR2} readOnly={true}/>
                                    </div>
                                    <div className="input-group">
                                        <label style={{width: "110px"}}>AdditionalInput2:</label>
                                        <input type="text" value={response.additionalInput2} readOnly={true}/>
                                    </div>
                                </>
                            }

                            <div className="input-group">
                                <label style={{width: "110px"}}>ReturnedBits 1:</label>
                                <input type="text" style={{color: "gray", fontSize:"10px"}} value={response.returnedBits1}
                                       readOnly={true}/>
                            </div>
                            <div className="input-group">
                                <label style={{width: "110px"}}>ReturnedBits 2:</label>
                                <input type="text" style={{color: "blue", fontSize:"10px"}} value={response.returnedBits2}
                                       readOnly={true}/>
                            </div>
                        </div>
                    }


                </div>
            )}
        </div>
    );
};

export default DrbgAlgorithm;
